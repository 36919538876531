<template>
  <div
    id="basic-info"
    class="card mt-4"
  >
    <div
      v-if="title || intro"
      class="card-header"
    >
      <h5 v-if="title">
        {{ title }}
      </h5>

      <p
        v-if="intro"
        class="text-sm"
      >
        {{ intro }}
      </p>
    </div>

    <div class="card-body pt-0">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseFieldset',

  props: {
    intro: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
};
</script>
