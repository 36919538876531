<template>
  <div
    ref="collapseRef"
    class="collapse"
    :class="classes"
    
  >
    <slot/>
  </div>
</template>

<script>

export default {
  props: {
    isOpen: { type: Boolean, default: false }
  },

  data() {
    return {
      collapseId: `collapse_${crypto.randomUUID()}`
    }
  },
  
  computed: {
    isOpened() { 
      return this.isOpen;
    },

    classes() {
      return [
        this.isOpened ? "show" : "show collapsing"
      ]
    }
  },
}
</script>